const buttons = document.getElementsByClassName("product-card__add-to-cart--simple");

if (buttons.length) {
  for (const button of buttons) {
    button.addEventListener('click', function (e) {
      e.preventDefault();
      const loader = button.parentNode;
      loader.classList.add('running');

      const data = new FormData();
      data.append('product_id', this.getAttribute('data-product-id'));

      fetch(productCard.restUrl + '/cart', {method: 'post', body: data})
        .then(response => {
          loader.classList.remove('running');
          if (response.ok) return response.json();
        }).then(json => {
        const el = document.createElement("div");
        el.innerHTML = json.html;
        loader.parentNode.insertBefore(el, loader.nextSibling);
      });
    })
  }
}
